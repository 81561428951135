import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../../components/seo"
const BlogPost = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.mdx.frontmatter.title} />
      <div className="container">
        <div className="main-content__container">
          <div className="main-content__body">
            <h1 className="main-content__title main-content__title--post">
              {data?.mdx.frontmatter.title}
            </h1>
            <div className="main-content__content">
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`
export default BlogPost
